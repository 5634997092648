import React, { useEffect, useState } from "react";
import oldLogo from "./assets/old logo.png";
import nextLogo from "./assets/Union 1.png";
import newLogo from "./assets/new logo.png";

const Home = () => {
  const [windowDimensions, setWindowDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions({
        width: window.innerWidth,
        height: window.innerHeight
      });
    };

    window.addEventListener('resize', handleResize);

    // Cleanup event listener on component unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className="container">
      <div className="box">
        <div className="inner-box">
          <div className="heading1">Yarnit has a new address</div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <div className="heading2">
            Explore the latest features and a seamless experience at our new website
            </div>
          </div>
          <div
          className="logo-container">
            <img src={oldLogo} height={97} width={209} />
            <img src={nextLogo} height={21} width={24} />
            {/* <img src={newLogo} height={84} width={206} /> */}
            <img src={newLogo} height={73} width={175} />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
              zIndex:"99999999"
            }}
          >
            <button className="takeme"
            onClick={() => {
                window.open("https://hub.yarnit.app/")
            }}
            >Take me there</button>
          </div>
        </div>

        <div className="forground">

        </div>
      </div>
    </div>
  );
};

export default Home;
