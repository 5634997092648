import logo from './logo.svg';
import './App.css';
import Home from './Home';
import { Router, Route, BrowserRouter, Routes } from 'react-router-dom';


function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route  element={<Home />} path='*' />

      </Routes>
    </BrowserRouter>
  );
}

export default App;
